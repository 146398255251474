<template>
  <v-row class="ma-3 help-container">
    <v-col
      v-for="item in items"
      :key="item.title"
    >
      <v-card
        class="text-center px-3"
        width="310"
        height="350"
      >
        <v-avatar
          class="mt-7"
          color="#F2F8FF"
          size="89"
        >
          <v-icon class="ml-1">
            {{ item.icon }}
          </v-icon>
        </v-avatar>
        <v-card-title class="card-title justify-center pb-2">
          {{ item.title }}
        </v-card-title>
        <v-card-text>{{ item.description }}</v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="font-weight-bold pa-6"
            @click="openUrl(item.link)"
          >
            {{ item.button }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Help',
  data() {
    return {
      items: [
        { title: 'Request feature',
          description: 'Do you have a suggestion to improve the KICKBOX platform? Are you missing a feature? Let us know and we’ll get in touch.',
          icon: '$helpRequest',
          button: 'request',
          link: 'https://getkickbox.typeform.com/to/Ei3q4nwV'
        },
        { title: 'Report bug',
          description: 'Something’s not working? Are you experiencing a problem on the platform? Please reach out and provide us with the details.',
          icon: '$helpBug',
          button: 'report',
          link: 'https://getkickbox.typeform.com/to/IBUQ60wp'
        },
        { title: 'Manuals and release notes',
          description: 'Find here a collection of manuals helping you to use the platform to the fullest. We also release new features regularly. Check them out!',
          icon: '$helpInfo',
          button: 'visit',
          link: 'https://v2-embednotion.com/KICKBOX-Release-Notes-and-Manuals-56592fa6e2024526934ab27a29a8ca1f'
        },
      ]
    };
  },
  methods: {
    openUrl(url) {
      window.open(url);
    }
  }
};
</script>

<style scoped lang="scss">
.help-container {
  max-width: 1100px;
  margin-left: 2rem !important;
  .v-icon {
    width: 60px !important;
    height: 60px !important;
  }
  .v-avatar {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .card-title {
    font-size: 18px;
    font-weight: 600;
  }
  .v-card__text {
    line-height: 1.2rem !important;
  }
}
</style>
