<template>
  <v-container>
    <v-card class="content-card-padding">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="certificates"
        :search="search"
        :custom-filter="customFilter"
        no-data-text="No Certificates"
        class="table-container"
        :footer-props="{
          itemsPerPageText: 'Certificates per page:',
          itemsPerPageOptions: [12, 24, 36, { text: 'All', value: -1 }]
        }"
      >
        <template
          v-if="isLoading.certificates"
          #body
        >
          <table-skeleton-loader :headers="headers" />
        </template>
        <template
          v-else
          #item="{ item }"
        >
          <tr>
            <td>{{ item.certificateId }}</td>
            <td>{{ item.kickboxerName }}</td>
            <td>{{ item.phase }}</td>
            <td>{{ item.certificationDate | formatDate }}</td>
            <td>{{ item.projectTitle }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { TableSkeletonLoader } from '@kickbox/common-admin';
import { mapGetters } from 'vuex';
import moment from 'moment';
import airtableService from '@/services/airtableService';

export default {
  name: 'Certificates',
  components: {
    TableSkeletonLoader
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Certificate Id', value: 'certificateId', align: 'left', width: 140 },
        { text: 'Kickboxer Name', value: 'kickboxerName', align: 'left', width: 160 },
        { text: 'Phase', value: 'phase', align: 'left' },
        { text: 'Date of Certification', value: 'certificationDate', align: 'left', width: 180 },
        { text: 'Project', value: 'projectTitle', align: 'left' },
      ]
    };
  },
  computed: {
    ...mapGetters([
      'certificates',
      'company',
      'isLoading',
      'projects'
    ]),
    searchResult() {
      return this.certificates.filter((t) => this.customFilter(null, this.search, t));
    }
  },
  async created() {
    if (!this.certificates.length) {
      await airtableService.getCertificates();
    }
  },
  methods: {
    customFilter(value, search, item) {
      const customSearch = search.toString().toLowerCase();
      return moment(item.certificationDate).format('MMM DD, YYYY').toLowerCase().includes(customSearch)
          || item.kickboxerName.toLowerCase().includes(customSearch)
          || item.certificateId.toLowerCase().includes(customSearch)
          || item.phase.toLowerCase().includes(customSearch)
          || item.projectTitle.toLowerCase().includes(customSearch);
    }
  }
};
</script>

<style scoped>

</style>
