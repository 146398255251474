import Parse from '@kickbox/common-util/src/parse';
import store from '@/store';

export default {
  async getCertificates() {
    store.commit('setIsLoading', { certificates: true });
    const allCertificates = await Parse.Cloud.run('getCertificates');
    const { projects } = store.getters;
    const projectMap = new Map(projects.map((p) => [p.id, p.title]));
    const certificates = allCertificates.map((c) => ({
      ...c,
      projectTitle: projectMap.get(c.projectId) || 'Not Available'
    }));
    store.commit('setCertificates', certificates);
    store.commit('setIsLoading', { certificates: false });
  },
  certificateExists({ table, projectId, email, phase }) {
    return Parse.Cloud.run('certificateExists', { table, projectId, email, phase });
  }
};
